import { dom } from "../utils/helpers";

interface FilterChangeEventDetail {
  genres: string[];
  actor: string;
  company: string;
  collection: string;
  franchise: string;
}

export class AdvancedFilters {
    selectedGenres: Set<string> = new Set();

    constructor() {
        this.setupEventListeners();
    }

  setupEventListeners(): void {
        const toggleButton = dom.$("#toggleFiltersButton") as HTMLButtonElement | null;
        const filtersSection = dom.$("#additionalFilters") as HTMLElement | null;
        const toggleIcon = dom.$("#toggleIcon") as HTMLElement | null;
      const genreCheckboxes = document.querySelectorAll(".filter-checkbox") as NodeListOf<HTMLInputElement>;
    
    if (toggleButton && filtersSection && toggleIcon) {
        dom.on(toggleButton, "click", () => {
            filtersSection.classList.toggle("hidden");
            const path = toggleIcon.querySelector("path");
            if (path) {
                path.setAttribute(
                    "d",
                    filtersSection.classList.contains("hidden")
                        ? "M19 9l-7 7-7-7"
                        : "M19 15l-7-7-7 7"
                );
            }
        });
    }
    
    // Handle genre selection
    genreCheckboxes.forEach((checkbox) => {
      dom.on(checkbox, "change", (e) => {
            if ((e.target as HTMLInputElement).checked) {
                this.selectedGenres.add((e.target as HTMLInputElement).value);
            } else {
              this.selectedGenres.delete((e.target as HTMLInputElement).value);
            }
            this.emitFilterChange();
        });
    });

      // Handle actor search
        const actorSearchInput = dom.$("#actorSearchInput") as HTMLInputElement | null;
    if (actorSearchInput) {
          dom.on(actorSearchInput, "input", (e) => {
            this.handleActorSearch((e.target as HTMLInputElement).value);
          });
      }
      
    // Handle company search
    const companySearchInput = dom.$("#companySearchInput") as HTMLInputElement | null;
    if (companySearchInput) {
        dom.on(companySearchInput, "input", (e) => {
          this.handleCompanySearch((e.target as HTMLInputElement).value);
        });
    }

    // Handle collection search
    const collectionSearchInput = dom.$("#collectionSearchInput") as HTMLInputElement | null;
    if (collectionSearchInput) {
        dom.on(collectionSearchInput, "input", (e) => {
          this.handleCollectionSearch((e.target as HTMLInputElement).value);
        });
    }
    
    // Handle franchise search
    const franchiseSearchInput = dom.$("#franchiseSearchInput") as HTMLInputElement | null;
    if (franchiseSearchInput) {
        dom.on(franchiseSearchInput, "input", (e) => {
          this.handleFranchiseSearch((e.target as HTMLInputElement).value);
        });
    }
    }

  emitFilterChange(): void {
        // Emit a custom event with the current filter state
        const filterChangeEvent = new CustomEvent<FilterChangeEventDetail>("filterChange", {
          detail: {
                genres: Array.from(this.selectedGenres),
                actor: (dom.$("#actorSearchInput") as HTMLInputElement | null)?.value || "",
                company: (dom.$("#companySearchInput") as HTMLInputElement | null)?.value || "",
                collection: (dom.$("#collectionSearchInput") as HTMLInputElement | null)?.value || "",
                franchise: (dom.$("#franchiseSearchInput") as HTMLInputElement | null)?.value || "",
            },
        });
        window.dispatchEvent(filterChangeEvent);
    }

  async handleActorSearch(query: string): Promise<void> {
      if (query.length < 2) return;
      // Implement actor search logic here
      this.emitFilterChange();
  }

  async handleCompanySearch(query: string): Promise<void> {
      if (query.length < 2) return;
      // Implement company search logic here
      this.emitFilterChange();
  }

  async handleCollectionSearch(query: string): Promise<void> {
      if (query.length < 2) return;
      // Implement collection search logic here
      this.emitFilterChange();
  }

    async handleFranchiseSearch(query: string): Promise<void> {
        if (query.length < 2) return;
        // Implement franchise search logic here
        this.emitFilterChange();
    }

  getSelectedGenres(): string[] {
        return Array.from(this.selectedGenres);
    }
  
  clearFilters(): void {
        this.selectedGenres.clear();
        const inputs = [
            "#actorSearchInput",
            "#companySearchInput",
            "#collectionSearchInput",
            "#franchiseSearchInput",
        ];
        inputs.forEach((selector) => {
            const input = dom.$(selector) as HTMLInputElement | null;
            if (input) input.value = "";
        });
        document.querySelectorAll(".filter-checkbox").forEach((checkbox) => {
            (checkbox as HTMLInputElement).checked = false;
        });
      this.emitFilterChange();
  }
}

import { API_CONFIG } from "./config";

interface BaseResults {
  page: number;
  total_pages: number;
  total_results: number;
}

interface MediaItem {
  id: number;
  title?: string;
  name?: string;
  release_date?: string;
  first_air_date?: string;
  poster_path?: string;
  vote_average: number;
  popularity: number;
  overview: string;
  vote_count: number;
    media_type?: "movie" | "tv";
    episode_run_time?: number[]
}

interface SearchResults extends BaseResults {
  results: MediaItem[];
}

interface ReleaseDate {
  certification: string;
  release_date: string;
  type: number;
}

interface ReleaseDatesResult {
  iso_3166_1: string;
  release_dates: ReleaseDate[];
}

interface ReleaseDatesData {
  results: ReleaseDatesResult[];
}

interface WatchProvidersData {
  results?: {
    [key: string]: {
        flatrate?: { logo_path: string, provider_id: number, provider_name: string }[],
        rent?: { logo_path: string, provider_id: number, provider_name: string }[],
        buy?: { logo_path: string, provider_id: number, provider_name: string }[],
    }
  }
}

interface ReleaseInfo {
  releaseType: string;
  certifications: string;
}

interface Genre {
  id: number;
  name: string;
}

interface MediaDetails extends MediaItem{
    genres: Genre[];
    budget: number;
    revenue: number;
    runtime: number;
    episode_run_time?: number[];
  production_companies: { id: number, logo_path: string, name: string, origin_country: string }[],
  seasons?: {
      air_date: string,
      episode_count: number,
      id: number,
      name: string,
      overview: string,
      poster_path: string,
      season_number: number
  }[]
    original_language: string;
}


interface CastMember {
  adult: boolean;
  gender: number | null;
  id: number;
  known_for_department: string;
  name: string;
  original_name: string;
  popularity: number;
  profile_path: string | null;
  character: string;
  credit_id: string;
  order: number;
}

interface CastData {
  cast: CastMember[];
}

interface Episode {
    air_date: string;
    episode_number: number;
    id: number;
    name: string;
    overview: string;
    production_code: string;
    runtime: number;
    season_number: number;
    still_path: string;
    vote_average: number;
    vote_count: number;
}

interface SeasonDetails {
    episodes: Episode[]
}

interface PersonDetails {
    movie_credits: { cast: MediaItem[] };
    tv_credits: { cast: MediaItem[] };
}

interface CompanyDetails {
    description: string;
  headquarters: string;
  homepage: string;
  id: number;
  logo_path: string;
  name: string;
  origin_country: string;
  parent_company: string | null
}

interface CollectionDetails {
  id: number,
  name: string,
  overview: string,
  poster_path: string,
  backdrop_path: string,
  parts: MediaItem[]
}

const cache = new Map<string, ReleaseInfo>();

class ApiService {
    async fetchFromTMDB<T>(endpoint: string, params: Record<string, any> = {}): Promise<T> {
        const url = new URL(`${API_CONFIG.baseUrl}${endpoint}`);
        url.searchParams.append("api_key", API_CONFIG.key);
    
        // Add additional parameters
        Object.entries(params).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                url.searchParams.append(key, value);
            }
        });
    
      try {
        const response = await fetch(url.toString());
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return await response.json() as T;
        } catch (error: any) {
            console.error("Error fetching from TMDB:", error);
            throw error;
        }
    }
  
    async getSeasonDetails(tvId: string, seasonNumber: number): Promise<SeasonDetails> {
        return this.fetchFromTMDB<SeasonDetails>(`/tv/${tvId}/season/${seasonNumber}`, {
            language: "en-US",
            append_to_response: "credits,images",
        });
    }
    
    async getReleaseType(mediaId: string, mediaType: "movie" | "tv", region: string = "US"): Promise<ReleaseInfo> {
      try {
        const cacheKey = `${mediaId}_${mediaType}`;
        if (cache.has(cacheKey)) {
          return cache.get(cacheKey)!;
        }
    
        // For TV shows, only check watch providers
        if (mediaType === "tv") {
          const watchProvidersData = await this.fetchFromTMDB<WatchProvidersData>(
            `/${mediaType}/${mediaId}/watch/providers`
          );
          const isStreamingAvailable =
              this.checkStreamingAvailability(watchProvidersData);
          const isRentalOrPurchaseAvailable =
              this.checkRentalOrPurchaseAvailability(watchProvidersData);
    
            let releaseType = "Unknown Quality";
            if (isStreamingAvailable) {
                releaseType = "HD";
            } else if (isRentalOrPurchaseAvailable) {
                releaseType = "HD";
            }
    
            const result: ReleaseInfo = {
            releaseType,
                certifications: "TV Rating",
            };
            cache.set(cacheKey, result);
            return result;
        }
    
        // For movies, check both release dates and watch providers
          const [releaseDatesData, watchProvidersData] = await Promise.all([
              this.fetchFromTMDB<ReleaseDatesData>(`/${mediaType}/${mediaId}/release_dates`),
            this.fetchFromTMDB<WatchProvidersData>(`/${mediaType}/${mediaId}/watch/providers`),
        ]);

          const currentUtcDate = new Date(
              Date.UTC(
                  new Date().getUTCFullYear(),
                  new Date().getUTCMonth(),
                  new Date().getUTCDate()
              )
          );
    
          const releases = releaseDatesData.results.flatMap(
              (result) => result.release_dates
          );
          const certifications = this.extractCertifications(
              releaseDatesData,
              region
          );

        const isDigitalRelease = this.checkDigitalRelease(
              releases,
            currentUtcDate
        );
        const isInTheaters = this.checkTheaterRelease(releases, currentUtcDate);
          const hasFutureRelease = this.checkFutureRelease(
            releases,
            currentUtcDate
        );
        const isStreamingAvailable =
            this.checkStreamingAvailability(watchProvidersData);
        const isRentalOrPurchaseAvailable =
            this.checkRentalOrPurchaseAvailability(watchProvidersData);
    
            const releaseType = this.determineReleaseType({
                isInTheaters,
                isStreamingAvailable,
                isDigitalRelease,
                hasFutureRelease,
                isRentalOrPurchaseAvailable,
          });
        
          const result: ReleaseInfo = { releaseType, certifications };
          cache.set(cacheKey, result);
        return result;
      } catch (error: any) {
          console.error(
          "Error fetching release type and certifications:",
              error.message
        );
            return {
                releaseType: "Unknown Quality",
                certifications: "No Certification Available",
            };
      }
  }
    
  extractCertifications(releaseDatesData: ReleaseDatesData, region: string): string {
        const certifications: { [key: string]: string } = {};
        releaseDatesData.results.forEach((result) => {
            const certificationEntry = result.release_dates.find(
                (release) => release.certification
            );
            if (certificationEntry) {
                certifications[result.iso_3166_1] = certificationEntry.certification;
            }
        });
        return certifications[region] || "No Certification Available";
    }

  checkDigitalRelease(releases: ReleaseDate[], currentUtcDate: Date): boolean {
      return releases.some(
          (release) =>
              [4, 6].includes(release.type) &&
              new Date(release.release_date).getTime() <= currentUtcDate.getTime()
      );
    }

    checkTheaterRelease(releases: ReleaseDate[], currentUtcDate: Date): boolean {
        return releases.some((release) => {
            const releaseDate = new Date(release.release_date);
          return (
                release.type === 3 && releaseDate.getTime() <= currentUtcDate.getTime()
          );
        });
    }

    checkFutureRelease(releases: ReleaseDate[], currentUtcDate: Date): boolean {
      return releases.some(
        (release) =>
          new Date(release.release_date).getTime() > currentUtcDate.getTime()
      );
    }

    checkStreamingAvailability(watchProvidersData: WatchProvidersData): boolean {
        const availableRegions = Object.keys(watchProvidersData.results || {});
        return availableRegions.some(
            (region) =>
              (watchProvidersData.results?.[region]?.flatrate || []).length > 0
        );
    }

    checkRentalOrPurchaseAvailability(watchProvidersData: WatchProvidersData): boolean {
        const availableRegions = Object.keys(watchProvidersData.results || {});
        return availableRegions.some((region) => {
            const rentProviders = watchProvidersData.results?.[region]?.rent || [];
            const buyProviders = watchProvidersData.results?.[region]?.buy || [];
            return rentProviders.length > 0 || buyProviders.length > 0;
        });
    }

  determineReleaseType({
    isInTheaters,
    isStreamingAvailable,
    isDigitalRelease,
      hasFutureRelease,
    isRentalOrPurchaseAvailable
  }: {
      isInTheaters: boolean;
      isStreamingAvailable: boolean;
      isDigitalRelease: boolean;
      hasFutureRelease: boolean;
      isRentalOrPurchaseAvailable: boolean;
  }): string {
      if (isInTheaters && !isStreamingAvailable && !isDigitalRelease) {
          return "Cam";
      } else if (isStreamingAvailable || isDigitalRelease) {
          return "HD";
      } else if (hasFutureRelease && !isInTheaters) {
          return "Not Released Yet";
      } else if (isRentalOrPurchaseAvailable) {
          return "Rental/Buy Available";
      } else {
          return "Unknown Quality";
      }
  }

    async searchMedia(query: string, type: "movie" | "tv" | "all" = "movie", page: number = 1): Promise<SearchResults> {
      if (type === "all") {
          // Search both movies and TV shows
          const [movieResults, tvResults] = await Promise.all([
            this.fetchFromTMDB<SearchResults>("/search/movie", {
                query,
                page,
                include_adult: false,
                language: "en-US",
            }),
              this.fetchFromTMDB<SearchResults>("/search/tv", {
                query,
                page,
                include_adult: false,
                language: "en-US",
            }),
        ]);
        
          // Combine and sort results by popularity
          return {
            results: [...movieResults.results, ...tvResults.results].sort(
                (a, b) => b.popularity - a.popularity
          ),
          page: movieResults.page,
          total_pages: Math.max(movieResults.total_pages, tvResults.total_pages),
          total_results: movieResults.total_results + tvResults.total_results
        };
      }
        
      return this.fetchFromTMDB<SearchResults>(`/search/${type}`, {
            query,
          page,
          include_adult: false,
            language: "en-US",
        });
    }
  
    async getMediaByCategory(
        category: string,
        type: "movie" | "tv" | "all" = "movie",
        page: number = 1,
        withGenres: string[] = []
    ): Promise<SearchResults> {
        if (type === "all") {
            // Get both movies and TV shows
            const [movieResults, tvResults] = await Promise.all([
              this.getMediaByCategory(category, "movie", page, withGenres),
                this.getMediaByCategory(category, "tv", page, withGenres),
            ]);
            
            // Add media_type to each result
            const moviesWithType = movieResults.results.map((movie) => ({
              ...movie,
                media_type: "movie",
            }));
            const tvWithType = tvResults.results.map((tv) => ({
                ...tv,
                media_type: "tv",
            }));
            
            // Combine and sort results by popularity
            return {
                results: [...moviesWithType, ...tvWithType]
                  .sort((a, b) => b.popularity - a.popularity)
                  .slice(0, 20), // Limit to 20 items per page
                total_pages: Math.max(
                    movieResults.total_pages || 1,
                    tvResults.total_pages || 1
                ),
                page: movieResults.page,
                total_results: movieResults.total_results + tvResults.total_results
            };
        }
      
        // If genres are selected, use discover endpoint
      if (withGenres.length > 0) {
            const params: Record<string, any> = {
            language: "en-US",
                sort_by: "popularity.desc",
            with_genres: withGenres.join(","),
            include_adult: false,
            include_video: false,
            vote_count_gte: 10,
              page,
        };
    
            // Add category-specific parameters
            switch (category) {
                case "upcoming":
                    const today = new Date();
                    const threeMonthsFromNow = new Date();
                    threeMonthsFromNow.setMonth(today.getMonth() + 3);
                    params.release_date_gte = today.toISOString().split("T")[0];
                    params.release_date_lte = threeMonthsFromNow
                        .toISOString()
                      .split("T")[0];
                    params.vote_count_gte = 0;
                    break;
                case "now_playing":
                    const oneMonthAgo = new Date();
                    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
                    params.release_date_gte = oneMonthAgo.toISOString().split("T")[0];
                    params.release_date_lte = new Date().toISOString().split("T")[0];
                    break;
                case "top_rated":
                    params.sort_by = "vote_average.desc";
                    params.vote_count_gte = 100;
                  params["vote_average.gte"] = 7;
                    break;
          }
            
          // For TV shows, adjust parameters
          if (type === "tv") {
                delete params.release_date_gte;
              delete params.release_date_lte;
    
                switch (category) {
                  case "on_the_air":
                      params.air_date_gte = new Date().toISOString().split("T")[0];
                    break;
                  case "airing_today":
                      const today = new Date().toISOString().split("T")[0];
                    params.air_date_gte = today;
                    params.air_date_lte = today;
                    break;
                }
            }
        
          const results = await this.fetchFromTMDB<SearchResults>(`/discover/${type}`, params);

        // Add media_type to each result
        results.results = results.results.map((item) => ({
            ...item,
                media_type: type,
        }));

            return results;
        }
      
      // If no genres selected, use category endpoint
      let endpoint: string = "";
        if (type === "movie") {
            switch (category) {
                case "popular":
                    endpoint = "/movie/popular";
                    break;
                case "top_rated":
                    endpoint = "/movie/top_rated";
                  break;
                case "upcoming":
                    endpoint = "/movie/upcoming";
                    break;
                case "now_playing":
                    endpoint = "/movie/now_playing";
                    break;
                default:
                    endpoint = "/movie/popular";
            }
        } else {
            switch (category) {
                case "popular":
                    endpoint = "/tv/popular";
                  break;
                case "top_rated":
                  endpoint = "/tv/top_rated";
                    break;
              case "on_the_air":
                    endpoint = "/tv/on_the_air";
                    break;
                case "airing_today":
                    endpoint = "/tv/airing_today";
                    break;
                default:
                    endpoint = "/tv/popular";
            }
        }
    
      const results = await this.fetchFromTMDB<SearchResults>(endpoint, {
            page,
          language: "en-US",
        });
    
        // Add media_type to each result
      results.results = results.results.map((item) => ({
            ...item,
            media_type: type,
        }));
    
        return results;
  }
    
    async getMediaDetails(id: string, type: "movie" | "tv" = "movie"): Promise<MediaDetails> {
        return this.fetchFromTMDB<MediaDetails>(`/${type}/${id}`, {
            append_to_response: "videos,credits,similar,recommendations",
            language: "en-US",
        });
    }
    
  async getCastData(id: string, type: "movie" | "tv" = "movie"): Promise<CastData> {
        return this.fetchFromTMDB<CastData>(`/${type}/${id}/credits`);
    }
  
    async searchPerson(query: string): Promise<SearchResults> {
        return this.fetchFromTMDB<SearchResults>("/search/person", {
          query,
            include_adult: false,
            language: "en-US",
        });
    }
    
    async getPersonDetails(id: string): Promise<PersonDetails> {
        return this.fetchFromTMDB<PersonDetails>(`/person/${id}`, {
            append_to_response: "movie_credits,tv_credits",
            language: "en-US",
        });
    }
    
    async searchCompany(query: string): Promise<SearchResults> {
        return this.fetchFromTMDB<SearchResults>("/search/company", {
          query,
            language: "en-US",
        });
    }
  
    async getCompanyDetails(id: string): Promise<CompanyDetails> {
        return this.fetchFromTMDB<CompanyDetails>(`/company/${id}`, {
            language: "en-US",
        });
    }
    
    async searchCollection(query: string): Promise<SearchResults> {
        return this.fetchFromTMDB<SearchResults>("/search/collection", {
          query,
            language: "en-US",
        });
    }

  async getCollectionDetails(id: string): Promise<CollectionDetails> {
        return this.fetchFromTMDB<CollectionDetails>(`/collection/${id}`, {
          language: "en-US",
        });
    }

    async getMediaByCompany(companyId: string, type: "movie" | "tv" = "movie", page: number = 1): Promise<SearchResults> {
        return this.fetchFromTMDB<SearchResults>(`/discover/${type}`, {
            with_companies: companyId,
          page,
            language: "en-US",
        });
    }
    
  async getMediaByCollection(collectionId: string, page: number = 1): Promise<CollectionDetails> {
        return this.fetchFromTMDB<CollectionDetails>(`/collection/${collectionId}`, {
            page,
            language: "en-US",
        });
  }
    
    async getMediaByPerson(personId: string, type: "movie" | "tv" = "movie"): Promise<MediaItem[]> {
        const details = await this.getPersonDetails(personId);
      return type === "movie" ? details.movie_credits.cast : details.tv_credits.cast
    }
}

export const apiService = new ApiService();
export type { MediaItem, SearchResults, ReleaseInfo, MediaDetails, CastData, CastMember, Episode, SeasonDetails, PersonDetails, CompanyDetails, CollectionDetails }

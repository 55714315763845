// DOM utility functions
    export const dom = {
      // Query selector with error handling
      $(selector: string, parent: ParentNode = document): HTMLElement | null {
          const element = parent.querySelector(selector) as HTMLElement | null;
          if (!element && selector !== '#loadingScreen') {
              console.warn(`Element not found: ${selector}`);
          }
          return element;
      },

      // Query selector all with error handling
      $$(selector: string, parent: ParentNode = document): NodeListOf<HTMLElement> {
          const elements = parent.querySelectorAll(selector) as NodeListOf<HTMLElement>;
          if (!elements.length) {
              console.warn(`No elements found: ${selector}`);
          }
          return elements;
      },

      // Event listener with error handling
      on(element: HTMLElement | null, event: string, handler: EventListenerOrEventListenerObject): void {
          if (!element) {
              console.warn('Cannot add event listener to null element');
              return;
          }
          element.addEventListener(event, handler);
      },

      // Remove event listener with error handling
      off(element: HTMLElement | null, event: string, handler: EventListenerOrEventListenerObject): void {
          if (!element) {
              console.warn('Cannot remove event listener from null element');
              return;
          }
          element.removeEventListener(event, handler);
      },


      create<K extends keyof HTMLElementTagNameMap>(
          tag: K,
          attributes: { [key: string]: any } = {},
          children: (string | Node)[] = []
      ): HTMLElementTagNameMap[K] {
          const element = document.createElement(tag);

          // Set attributes
          Object.entries(attributes).forEach(([key, value]) => {
              if (key === 'className') {
                  element.className = value;
              } else if (key === 'dataset') {
                  Object.entries(value).forEach(([dataKey, dataValue]) => {
                      (element as any).dataset[dataKey] = dataValue;
                  });
              } else {
                  element.setAttribute(key, value);
              }
          });

          // Append children
          children.forEach(child => {
              if (typeof child === 'string') {
                  element.appendChild(document.createTextNode(child));
              } else if (child instanceof Node) {
                  element.appendChild(child);
              }
          });

          return element;
      },

      // Add multiple classes
      addClass(element: HTMLElement | null, ...classes: string[]): void {
          if (!element) {
              console.warn('Cannot add class to null element');
              return;
          }
          element.classList.add(...classes);
      },

      // Remove multiple classes
      removeClass(element: HTMLElement | null, ...classes: string[]): void {
          if (!element) {
              console.warn('Cannot remove class from null element');
              return;
          }
          element.classList.remove(...classes);
      },

      // Toggle class with optional force parameter
      toggleClass(element: HTMLElement | null, className: string, force?: boolean): void {
          if (!element) {
              console.warn('Cannot toggle class on null element');
              return;
          }
          element.classList.toggle(className, force);
      },

      // Check if element has class
      hasClass(element: HTMLElement | null, className: string): boolean {
          if (!element) {
              console.warn('Cannot check class on null element');
              return false;
          }
          return element.classList.contains(className);
      },

       // Set or get data attribute
      data(element: HTMLElement | null, key: string, value?: string): string | undefined {
          if (!element) {
              console.warn('Cannot access data attribute on null element');
              return;
          }
          if (value === undefined) {
            return element.dataset[key];
          }
           element.dataset[key] = value;
      },


      // Show element (remove hidden class)
      show(element: HTMLElement | null): void {
          if (!element) {
              console.warn('Cannot show null element');
              return;
          }
          element.classList.remove('hidden');
      },

      // Hide element (add hidden class)
      hide(element: HTMLElement | null): void {
          if (!element) {
              console.warn('Cannot hide null element');
              return;
          }
          element.classList.add('hidden');
      },

      // Toggle element visibility
      toggle(element: HTMLElement | null): void {
          if (!element) {
              console.warn('Cannot toggle null element');
              return;
          }
          element.classList.toggle('hidden');
      }
    };
